import React, { useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Importar los íconos
import logo from "../images/logo.png";
import whatsapp from "../images/whatsapp.png";
import facebook from "../images/facebook.png"
import instagram from "../images/instagram.png"
import '../styles/SeleccionarServicios.css';

const serviciosData = [
    {
        categoria: "Manicure",
        servicios: [
            { id: 1, nombre: "Recubrimiento Poly Gel", precio: 65000, tiempo: "150 minutos", descripcion: "", imagen: "polygel.jpg" },
            { id: 2, nombre: "Acrílico Esculpido", precio: 75000, tiempo: "150 minutos", descripcion: "", imagen: "default.jpeg" },
            { id: 3, nombre: "Acrílico Esculpido", precio: 80000, tiempo: "150 minutos", descripcion: "", imagen: "default.jpeg" },
            { id: 4, nombre: "Acrílico Esculpido", precio: 85000, tiempo: "150 minutos", descripcion: "", imagen: "default.jpeg" },
            { id: 5, nombre: "Recubrimiento Acrílico", precio: 65000, tiempo: "150 minutos", descripcion: "", imagen: "recubrimiento-acrilico.jpg" },
            { id: 6, nombre: "Poly Gel Esculpido", precio: 75000, tiempo: "150 minutos", descripcion: "", imagen: "poly-gel-esculpido.jpg" },
            { id: 7, nombre: "Poly Gel Esculpido", precio: 80000, tiempo: "150 minutos", descripcion: "", imagen: "poly-gel-esculpido.jpg" },
            { id: 8, nombre: "Poly Gel Esculpido", precio: 85000, tiempo: "150 minutos", descripcion: "", imagen: "poly-gel-esculpido.jpg" },
            { id: 9, nombre: "Manos Semipermanente", precio: 40000, tiempo: "80 minutos", descripcion: "", imagen: "manos-semipermanente.jpg" },
            { id: 10, nombre: "Press On", precio: 60000, tiempo: "120 minutos", descripcion: "", imagen: "press-on.jpg" },
            { id: 11, nombre: "Base Ruber", precio: 50000, tiempo: "60 minutos", descripcion: "", imagen: "base-ruber.jpg" },
            { id: 12, nombre: "Baño en Acrílico", precio: 60000, tiempo: "120 minutos", descripcion: "", imagen: "bano-acrilico.jpg" },
            { id: 13, nombre: "Mano Tradicional Liso y Frances", precio: 15000, tiempo: "40 minutos", descripcion: "", imagen: "mano-tradicional-frances.jpg" },
            { id: 14, nombre: "Manos Tradicional Diseño", precio: 20000, tiempo: "60 minutos", descripcion: "", imagen: "default.jpeg" },
            { id: 15, nombre: "Adicional de Uña en Manos", precio: 8000, tiempo: "15 minutos", descripcion: "", imagen: "default.jpeg" }
        ],       
    },
    {
        categoria: "Pedicure",
        servicios: [
            { id: 16, nombre: 'Pies semi permanentes', precio: 35000, tiempo: '90 minutos', descripcion: '', imagen: 'pedicure-semipermanente.jpg' },
    { id: 17, nombre: 'Pies tradicional liso y frances', precio: 15000, tiempo: '50 minutos', descripcion: '', imagen: 'pies-tradicional-liso-frances.jpg' },
    { id: 18, nombre: 'Pies tradicional diseño', precio: 20000, tiempo: '60 minutos', descripcion: '', imagen: 'default.jpeg' },
    { id: 19, nombre: 'Adicional de uña en pies', precio: 5000, tiempo: '15 minutos', descripcion: '', imagen: 'default.jpeg' },
    { id: 20, nombre: 'Retiro semipermanente', precio: 5000, tiempo: '15 minutos', descripcion: '', imagen: 'default.jpeg' },
    { id: 21, nombre: 'Retiro de recubrimiento', precio: 10000, tiempo: '15 minutos', descripcion: '', imagen: 'default.jpeg' },
    { id: 22, nombre: 'Retiro de esculpido', precio: 15000, tiempo: '25 minutos', descripcion: '', imagen: 'default.jpeg' },
    { id: 23, nombre: 'Mantenimiento de recubrimiento', precio: 50000, tiempo: '140 minutos', descripcion: '', imagen: 'default.jpeg' },
    { id: 24, nombre: 'Mantenimiento de esculpido', precio: 55000, tiempo: '120 minutos', descripcion: '', imagen: 'default.jpeg' },
    { id: 25, nombre: 'Mantenimiento de press', precio: 50000, tiempo: '120 minutos', descripcion: '', imagen: 'default.jpeg' }
        ],
    },
    {
        categoria: "Cabello y Maquillaje",
        servicios: [
            { id: 26, nombre: 'Lavado de cabello', precio: 5000, tiempo: '8 minutos', descripcion: '', imagen: 'lavado-cabello.jpg' },
            { id: 27, nombre: 'Planchado de cabello', precio: 15000, tiempo: '30 minutos', descripcion: '', imagen: 'planchado-cabello.jpg' },
            { id: 28, nombre: 'Cepillado de cabello', precio: 15000, tiempo: '30 minutos', descripcion: '', imagen: 'cepillado-cabello.jpg' },
            { id: 29, nombre: 'Ondas de cabello', precio: 20000, tiempo: '30 minutos', descripcion: '', imagen: 'ondas-cabello.jpg' },
            { id: 30, nombre: 'Peinados', precio: 10000, tiempo: '40 minutos', descripcion: '', imagen: 'default.jpeg' },
            { id: 31, nombre: 'Repolarización', precio: 40000, tiempo: '80 minutos', descripcion: '', imagen: 'default.jpeg' },
            { id: 32, nombre: 'Hidratación', precio: 40000, tiempo: '80 minutos', descripcion: '', imagen: 'default.jpeg' },
            { id: 33, nombre: 'Keratina', precio: 100000, tiempo: '320 minutos', descripcion: '', imagen: 'keratina.jpg' },
            { id: 34, nombre: 'Despunte', precio: 7000, tiempo: '8 minutos', descripcion: '', imagen: 'despunte.jpg' },
            { id: 35, nombre: 'Maquillaje sencillo', precio: 40000, tiempo: '20 minutos', descripcion: '', imagen: 'default.jpeg' },
            { id: 36, nombre: 'Maquillaje social', precio: 45000, tiempo: '30 minutos', descripcion: '', imagen: 'default.jpeg' },
            { id: 37, nombre: 'Maquillaje sencillo con pestañas', precio: 50000, tiempo: '30 minutos', descripcion: '', imagen: 'default.jpeg' },
            { id: 38, nombre: 'Maquillaje social con pestañas', precio: 55000, tiempo: '30 minutos', descripcion: '', imagen: 'default.jpeg' },
            { id: 39, nombre: 'Maquillaje artístico', precio: 10000, tiempo: '30 minutos', descripcion: '', imagen: 'default.jpeg' }
        ],
    },
    {
        categoria: "Cejas y Pestañas",
        servicios: [
            { id: 40, nombre: "Depilación de cejas con hojilla", precio: 5000, tiempo: "10 minutos", descripcion: null, imagen: "depilacion-hojilla.jpg" },
            { id: 41, nombre: "Depilación de cejas con cera", precio: 10000, tiempo: "15 minutos", descripcion: null, imagen: "depilacion-cejas.jpg" },
            { id: 42, nombre: "Depilación de bozo en cera", precio: 5000, tiempo: "5 minutos", descripcion: null, imagen: "depilacion-bozo-cera.jpg" },
            { id: 43, nombre: "Diseño de cejas", precio: 25000, tiempo: "30 minutos", descripcion: null, imagen: "diseno-cejas.jpg" },
            { id: 44, nombre: "Solo pigmentación", precio: 15000, tiempo: "15 minutos", descripcion: null, imagen: "solo-pigmentacion.jpg" },
            { id: 45, nombre: "Depilación de axilas", precio: 18000, tiempo: "15 minutos", descripcion: null, imagen: "depilacion-axilas.jpg" },
            { id: 46, nombre: "Laminado de cejas", precio: 60000, tiempo: "60 minutos", descripcion: null, imagen: "laminado-cejas.jpg" },
            { id: 47, nombre: "Lifting de pestañas", precio: 50000, tiempo: "60 minutos", descripcion: null, imagen: "lifting.jpg" },
            { id: 48, nombre: "Clásicas", precio: 80000, tiempo: "150 minutos", descripcion: null, imagen: "default.jpeg" },  // No se proporcionó imagen específica
            { id: 49, nombre: "Volumen", precio: 100000, tiempo: "180 minutos", descripcion: null, imagen: "default.jpeg" },  // No se proporcionó imagen específica
            { id: 50, nombre: "Pestaña por punto", precio: 25000, tiempo: "25 minutos", descripcion: null, imagen: "pestana-por-punto.jpg" },
        ],
        
    },
    // Agrega más categorías y servicios según sea necesario
];

function SeleccionarServicios() {
    const location = useLocation();
    const userId = location.state?.userId;
    const [activeCategory, setActiveCategory] = useState(null);
    const [selectedServices, setSelectedServices] = useState([]);
    const [isFloatingWindowOpen, setIsFloatingWindowOpen] = useState(false);
    const navigate = useNavigate();

    const toggleCategory = (index) => {
        setActiveCategory(activeCategory === index ? null : index);
    };

    const handleServiceClick = (servicio) => {
        const isSelected = selectedServices.some(s => s.id === servicio.id);
        if (isSelected) {
            setSelectedServices(selectedServices.filter(s => s.id !== servicio.id));
        } else {
            setSelectedServices([...selectedServices, servicio]);
        }
    };

    const handleProceedClick = () => {
        if (!userId || selectedServices.length === 0) {
            alert('Datos incompletos.');
            return;
        }

        const selectedServiceIds = selectedServices.map(service => service.id);
        navigate('/calendario', { state: { userId, selectedServices: selectedServiceIds } });
    };

    const totalPrecio = selectedServices.reduce((total, servicio) => total + servicio.precio, 0);

    return (
        <div className="contenedor-servicios">
            <div className="header">
                <img src={logo} alt="Logo" className="logo" />
                <h1>Agendamiento de Citas Olivia Wilson</h1>
            </div>
            <div className="servicios-lista">
                <h2>Servicios Disponibles</h2>
                {serviciosData.map((categoria, index) => (
                    <div key={index} className="categoria">
                        <h3 onClick={() => toggleCategory(index)} className="categoria-titulo">
                            {categoria.categoria}
                        </h3>
                        {activeCategory === index && (
                            <div className="servicios-container">
                                {categoria.servicios.map((servicio, idx) => (
                                    <div
                                        key={idx}
                                        className={`servicio ${selectedServices.includes(servicio) ? 'seleccionado' : ''}`}
                                        onClick={() => handleServiceClick(servicio)}
                                    >
                                        <div className="servicio-info">
                                            <h4>{servicio.nombre}</h4>
                                            <p>Precio: ${servicio.precio}</p>
                                            <p>Tiempo: {servicio.tiempo}</p>
                                            <p>Descripción: {servicio.descripcion}</p>
                                        </div>
                                        <img src={require(`../images/servicios/${servicio.imagen}`)} alt={servicio.nombre} className="servicio-imagen" />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <div className="floating-window">
                <div className="floating-window-header" onClick={() => setIsFloatingWindowOpen(!isFloatingWindowOpen)}>
                    <span className="service-count">{selectedServices.length}</span>
                    <h3>Servicios Seleccionados</h3>
                    {isFloatingWindowOpen ? <FaChevronUp /> : <FaChevronDown />}
                </div>
                {isFloatingWindowOpen && (
                    <>
                        <div className="selected-services-list">
                            {selectedServices.map(servicio => (
                                <div key={servicio.id} className="selected-service">
                                    <p>{servicio.nombre} - ${servicio.precio}</p>
                                    <button onClick={() => handleServiceClick(servicio)}>Eliminar</button>
                                </div>
                            ))}
                        </div>
                        <div className="total">
                            <h4>Total: ${totalPrecio}</h4>
                        </div>
                        <button className="proceed-button" onClick={handleProceedClick}>
                            Proceder a escoger la fecha
                        </button>
                    </>
                )}
            </div>

            <div className="footer">
  <p>Estamos aquí para hacerte sentir más hermosa y cuidada.</p>
  <div className="footer-menu">
    <div className="footer-option">
      <a href="https://www.instagram.com/palo_rosaarauca?igsh=MXNmMjBmd2tnajhsMw==" target="_blank" rel="noopener noreferrer">
        <img src={instagram} alt="Instagram" />
        <p>Instagram</p>
      </a>
    </div>
    <div className="footer-option">
      <a href="https://www.facebook.com/profile.php?id=100084094834817&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
        <img src={facebook} alt="Facebook" />
        <p>Facebook</p>
      </a>
    </div>
    <div className="footer-option">
      <a href="https://wa.me/+573219835941" target="_blank" rel="noopener noreferrer">
        <img src={whatsapp} alt="WhatsApp" />
        <p>WhatsApp</p>
      </a>
    </div>
  </div>
</div>
            <div className="credits">
                Esta página fue creada por <a href="mailto:medellincodestudios@gmail.com">Medellin Code Studios</a>
            </div>
        </div>
    );
}

export default SeleccionarServicios;





  


