import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/AdminPanel.css';

function ManageAppointments() {
  const [citas, setCitas] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date().toLocaleDateString('en-CA'));
  const [expandedCita, setExpandedCita] = useState(null);

  useEffect(() => {
    const fetchCitas = async () => {
      try {
        const response = await axios.get('http://oliviawilsoncitas.online/api/citas');
        setCitas(response.data);
      } catch (error) {
        console.error('Error fetching citas:', error);
      }
    };

    fetchCitas();

    const interval = setInterval(fetchCitas, 60000);

    return () => clearInterval(interval);
  }, []);

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const filteredCitas = citas
    .filter(cita => new Date(cita.fecha_hora).toLocaleDateString('en-CA') === selectedDate)
    .sort((a, b) => {
      const estados = ['programada', 'completada', 'cancelada'];
      return estados.indexOf(a.estado) - estados.indexOf(b.estado);
    });

  const getCitaColor = (estado) => {
    switch (estado) {
      case 'programada':
        return '#d4edda';
      case 'completada':
        return '#fff3cd';
      case 'cancelada':
        return '#f8d7da';
      default:
        return '#ffffff';
    }
  };

  return (
    <div className="section citas-section">
      <h3>Citas Programadas</h3>
      <input type="date" value={selectedDate} onChange={handleDateChange} />
      <div className="appointment-list">
        <ul>
          {filteredCitas.map((cita) => (
            <li 
              key={cita.id} 
              className={expandedCita === cita.id ? 'expanded-cita' : ''}
              style={{ backgroundColor: getCitaColor(cita.estado) }}
              onClick={() => setExpandedCita(expandedCita === cita.id ? null : cita.id)}
            >
              {expandedCita !== cita.id ? (
                <div>{`Servicio: ${cita.servicio_nombre}, Fecha y Hora: ${new Date(cita.fecha_hora).toLocaleString()}, Estado: ${cita.estado}`}</div>
              ) : (
                <>
                  <div>
                    <strong>Servicio:</strong> {cita.servicio_nombre}<br />
                    <strong>Duración:</strong> {cita.duracion} minutos<br />
                    <strong>Fecha y hora:</strong> {new Date(cita.fecha_hora).toLocaleString()}<br />
                    <strong>Estado:</strong> {cita.estado}
                  </div>
                  <div>
                    <strong>Nombre:</strong> {cita.first_name}<br />
                    <strong>Apellido:</strong> {cita.last_name}<br />
                    <strong>Celular:</strong> {cita.cellphone}<br />
                    <strong>Email:</strong> {cita.email}
                  </div>
                </>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ManageAppointments;
