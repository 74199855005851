import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/AdminPanel.css';

function ManageWorkers() {
  const [trabajadores, setTrabajadores] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [newWorker, setNewWorker] = useState({ nombre: '', categoria: '', disponible: true });

  useEffect(() => {
    const fetchTrabajadores = async () => {
      try {
        const response = await axios.get('http://oliviawilsoncitas.online/api/trabajadores');
        setTrabajadores(response.data);
      } catch (error) {
        console.error('Error fetching trabajadores:', error);
      }
    };

    const fetchCategorias = async () => {
      try {
        const response = await axios.get('http://oliviawilsoncitas.online/api/servicios/categorias');
        setCategorias(response.data);
      } catch (error) {
        console.error('Error fetching categorias:', error);
      }
    };

    fetchTrabajadores();
    fetchCategorias();
  }, []);

  const handleAddWorker = async () => {
    try {
      const response = await axios.post('http://oliviawilsoncitas.online/api/trabajadores', newWorker);
      setTrabajadores([...trabajadores, response.data]);
      setNewWorker({ nombre: '', categoria: '', disponible: true });
    } catch (error) {
      console.error('Error adding worker:', error);
    }
  };

  const handleToggleAvailability = async (id) => {
    try {
      const worker = trabajadores.find((worker) => worker.id === id);
      const updatedWorker = { ...worker, disponible: !worker.disponible };
      await axios.put(`http://oliviawilsoncitas.online/api/trabajadores/${id}`, updatedWorker);
      setTrabajadores(trabajadores.map((worker) => (worker.id === id ? updatedWorker : worker)));
    } catch (error) {
      console.error('Error toggling availability:', error);
    }
  };

  const handleDeleteWorker = async (id) => {
    try {
      await axios.delete(`http://oliviawilsoncitas.online/api/trabajadores/${id}`);
      setTrabajadores(trabajadores.filter((worker) => worker.id !== id));
    } catch (error) {
      console.error('Error deleting worker:', error);
    }
  };

  return (
    <div className="section trabajadores-section">
      <h3>Trabajadores</h3>
      <div className="add-worker">
        <input
          type="text"
          placeholder="Nombre"
          value={newWorker.nombre}
          onChange={(e) => setNewWorker({ ...newWorker, nombre: e.target.value })}
        />
        <select
          value={newWorker.categoria}
          onChange={(e) => setNewWorker({ ...newWorker, categoria: e.target.value })}
        >
          <option value="">Seleccionar Categoría</option>
          {categorias.map((cat, index) => (
            <option key={index} value={cat.categoria}>{cat.categoria}</option>
          ))}
        </select>
        <button className="btn btn-primary" onClick={handleAddWorker}>Agregar Trabajador</button>
      </div>
      <div className="worker-list">
        <ul>
          {trabajadores.map((worker) => (
            <li key={worker.id}>
              <div>
                <span>{worker.nombre}</span> - <span>{worker.categoria}</span>
              </div>
              <button className="btn btn-worker-toggle" onClick={() => handleToggleAvailability(worker.id)}>
                {worker.disponible ? 'Desactivar' : 'Activar'}
              </button>
              <button className="btn btn-delete" onClick={() => handleDeleteWorker(worker.id)}>Eliminar</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ManageWorkers;









