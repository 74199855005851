import React from 'react';
import { useLocation } from 'react-router-dom';
import logo from "../images/logo.png";

const Confirmacion = () => {
  const location = useLocation();
  const selectedServices = location.state?.selectedServices || [];

  return (
    <div className="confirmacion-container">
      <div className="header">
        <img src={logo} alt="Logo" className="logo" /> 
        <h1>Confirmación de Servicios</h1>
      </div>
      <div className="confirmacion-content">
        <h2>Servicios Seleccionados</h2>
        <ul>
          {selectedServices.map((servicio, index) => (
            <li key={index}>
              <h3>{servicio.nombre}</h3>
              <p>Precio: ${servicio.precio}</p>
              <p>Tiempo: {servicio.tiempo}</p>
              <p>Descripción: {servicio.descripcion}</p>
            </li>
          ))}
        </ul>
        <p>Gracias por seleccionar nuestros servicios. Nos pondremos en contacto con usted para confirmar los detalles de su cita.</p>
      </div>
    </div>
  );
};

export default Confirmacion;
