// src/components/HomePage.jsx
import React from "react";
import { Link } from "react-router-dom";

import logo from "../images/logo.png";
import whatsapp from "../images/whatsapp.png";
import facebook from "../images/facebook.png"
import instagram from "../images/instagram.png"

import "../styles/HomePage.css";

function HomePage() {
  return (
    <div className="pagina-principal">
      <div className="header">
        <img src={logo} alt="Logo" className="logo" />
        <h1>Agendamiento de Citas Olivia Wilson</h1>
      </div>
      <div className="welcome-banner">
        <h2>Bienvenida a nuestro servicio de agendamiento de citas</h2>
        <p>Aquí podrás agendar, cancelar o modificar tu cita para cualquier tratamiento de belleza.</p>
      </div>
      <div className="main-content">
        <div className="centered-container">
          <Link to="/auth">
            <button className="option-button">Solicitar Cita<span>➡</span></button>
          </Link>
          <Link to="/login-visualizar">
            <button className="option-button">Consultar o Cancelar Cita<span>➡</span></button>
          </Link>
        </div>
      </div>
      <div className="footer">
  <p>Estamos aquí para hacerte sentir más hermosa y cuidada.</p>
  <div className="footer-menu">
    <div className="footer-option">
      <a href="https://www.instagram.com/palo_rosaarauca?igsh=MXNmMjBmd2tnajhsMw==" target="_blank" rel="noopener noreferrer">
        <img src={instagram} alt="Instagram" />
        <p>Instagram</p>
      </a>
    </div>
    <div className="footer-option">
      <a href="https://www.facebook.com/profile.php?id=100084094834817&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
        <img src={facebook} alt="Facebook" />
        <p>Facebook</p>
      </a>
    </div>
    <div className="footer-option">
      <a href="https://wa.me/+573219835941" target="_blank" rel="noopener noreferrer">
        <img src={whatsapp} alt="WhatsApp" />
        <p>WhatsApp</p>
      </a>
    </div>
  </div>
</div>


      <div className="credits">
        Esta página fue creada por <a href="mailto:medellincodestudios@gmail.com">Medellin Code Studios</a>
      </div>
    </div>
  );
}

export default HomePage;





