import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../styles/CalendarioCitas.css';
import logo from "../images/logo.png";
import whatsapp from "../images/whatsapp.png";
import facebook from "../images/facebook.png"
import instagram from "../images/instagram.png"

function CalendarioCitas() {
  const [date, setDate] = useState(new Date());
  const [availableTimes, setAvailableTimes] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [citas, setCitas] = useState([]);
  const [serviciosData, setServiciosData] = useState([]);
  const [trabajadores, setTrabajadores] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const { userId, selectedServices } = location.state;

  useEffect(() => {
    const fetchCitas = async () => {
      try {
        const response = await axios.get('http://oliviawilsoncitas.online/api/citas');
        setCitas(response.data);
      } catch (error) {
        console.error('Error fetching citas:', error);
      }
    };

    const fetchServicios = async () => {
      try {
        const response = await axios.get('http://oliviawilsoncitas.online/api/servicios');
        setServiciosData(response.data);
      } catch (error) {
        console.error('Error fetching servicios:', error);
      }
    };

    const fetchTrabajadores = async () => {
      try {
        const response = await axios.get('http://oliviawilsoncitas.online/api/trabajadores');
        setTrabajadores(response.data);
      } catch (error) {
        console.error('Error fetching trabajadores:', error);
      }
    };

    fetchCitas();
    fetchServicios();
    fetchTrabajadores();
  }, []);

  useEffect(() => {
    const calculateTotals = () => {
      const totalDuration = selectedServices.reduce((acc, serviceId) => {
        const foundService = serviciosData.find(serv => serv.id === serviceId);
        return acc + (foundService?.duracion || 0);
      }, 0);
      const totalPrice = selectedServices.reduce((acc, serviceId) => {
        const foundService = serviciosData.find(serv => serv.id === serviceId);
        return acc + (parseFloat(foundService?.precio) || 0);
      }, 0);
      setTotalDuration(totalDuration);
      setTotalPrice(parseFloat(totalPrice));
    };
    calculateTotals();
  }, [selectedServices, serviciosData]);

  useEffect(() => {
    const getAvailableTimes = () => {
      const horarios = [];
      const startHour = date.getDay() === 0 || date.getDay() === 6 ? 11 : 10;
      const endHour = date.getDay() === 0 || date.getDay() === 6 ? 21 : 20;
      for (let hour = startHour; hour < endHour; hour++) {
        horarios.push(`${hour}:00`);
        horarios.push(`${hour}:30`);
      }

      const unavailableTimes = citas
        .filter(cita => new Date(cita.fecha_hora).toDateString() === date.toDateString())
        .map(cita => {
          const service = serviciosData.find(serv => serv.id === cita.servicio_id);
          const start = new Date(cita.fecha_hora);
          const end = new Date(start.getTime() + (service?.duracion || 0) * 60000);
          return { start, end, categoryId: service?.categoria };
        });

      const availableWorkersByCategory = {};
      trabajadores.forEach(worker => {
        if (worker.disponible) {
          if (!availableWorkersByCategory[worker.categoria]) {
            availableWorkersByCategory[worker.categoria] = [];
          }
          availableWorkersByCategory[worker.categoria].push(worker);
        }
      });

      const available = horarios.filter(hora => {
        const [hour, minutes] = hora.split(':').map(Number);
        const startTime = new Date(date);
        startTime.setHours(hour, minutes, 0, 0);

        const overlappingCitas = unavailableTimes.filter(unavailable => 
          (startTime >= unavailable.start && startTime < unavailable.end)
        );

        const isTimeAvailable = selectedServices.every(serviceId => {
          const foundService = serviciosData.find(serv => serv.id === serviceId);
          const category = foundService?.categoria;
          const availableWorkers = availableWorkersByCategory[category]?.length || 0;
          const usedWorkers = overlappingCitas.filter(cita => cita.categoryId === category).length;
          return availableWorkers > usedWorkers;
        });

        return isTimeAvailable;
      }).map(convertTo12HourFormat);

      setAvailableTimes(available);
    };

    getAvailableTimes();
  }, [date, citas, selectedServices, serviciosData, trabajadores]);

  const convertTo12HourFormat = (time) => {
    const [hour, minutes] = time.split(':').map(Number);
    const period = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12;
    return `${hour12}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;
  };

  const handleDateChange = (date) => {
    setDate(date);
    setSelectedTime(null);
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  const handleConfirmClick = async () => {
    try {
      await axios.post('http://oliviawilsoncitas.online/api/citas', {
        userId,
        serviceIds: selectedServices,
        dateTime: new Date(`${date.toDateString()} ${selectedTime}`)
      });
      window.alert('Cita programada exitosamente. ¡Gracias por tu reserva!');
      navigate('/');
    } catch (error) {
      console.error('Error scheduling cita:', error);
      alert('Error al programar la cita');
    }
  };

  const isDateDisabled = ({ date, view }) => {
    const today = new Date();
    const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());
    return view === 'month' && (date < today.setHours(0, 0, 0, 0) || date > nextMonth);
  };


  return (
    <div className='contenedor-citas'>
      <div className="header">
        <img src={logo} alt="Logo" className="logo" />
        <h1>Agendamiento de Citas Olivia Wilson</h1>
      </div>
      <div className="calendario-citas">
      <h2>Selecciona una fecha y hora para tu cita</h2>
      <Calendar
        onChange={handleDateChange}
        value={date}
        tileDisabled={isDateDisabled}
        minDate={new Date()}
        maxDate={new Date(new Date().setMonth(new Date().getMonth() + 1))}
      />
      {availableTimes.length > 0 && (
        <div className="horarios-disponibles">
          <h3>Horarios disponibles</h3>
          {availableTimes.map((time, index) => (
            <button
              key={index}
              className={`time-slot ${selectedTime === time ? 'selected' : ''}`}
              onClick={() => handleTimeSelect(time)}
            >
              {time}
            </button>
          ))}
        </div>
      )}
      {selectedTime && (
        <button onClick={handleConfirmClick}>
          Confirmar cita a las {selectedTime}
        </button>
      )}
      <div className="selected-services">
        <h3>Servicios seleccionados</h3>
        <ul>
          {selectedServices.map(serviceId => {
            const service = serviciosData.find(serv => serv.id === serviceId);
            return (
              <li key={serviceId}>
                {service?.nombre} - ${service?.precio} - {service?.duracion} min
              </li>
            );
          })}
        </ul>
        <p>Total Precio: ${totalPrice.toFixed(2)}</p>
        <p>Total Duración: {totalDuration} min</p>
      </div>
    </div>
    <div className="footer">
  <p>Estamos aquí para hacerte sentir más hermosa y cuidada.</p>
  <div className="footer-menu">
    <div className="footer-option">
      <a href="https://www.instagram.com/palo_rosaarauca?igsh=MXNmMjBmd2tnajhsMw==" target="_blank" rel="noopener noreferrer">
        <img src={instagram} alt="Instagram" />
        <p>Instagram</p>
      </a>
    </div>
    <div className="footer-option">
      <a href="https://www.facebook.com/profile.php?id=100084094834817&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
        <img src={facebook} alt="Facebook" />
        <p>Facebook</p>
      </a>
    </div>
    <div className="footer-option">
      <a href="https://wa.me/+573219835941" target="_blank" rel="noopener noreferrer">
        <img src={whatsapp} alt="WhatsApp" />
        <p>WhatsApp</p>
      </a>
    </div>
  </div>
</div>
      <div className="credits">
        Esta página fue creada por <a href="mailto:medellincodestudios@gmail.com">Medellin Code Studios</a>
      </div>
    </div>
  );
}

export default CalendarioCitas;















