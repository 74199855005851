import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles/UserDetailsForm.css';
import logo from "../images/logo.png";

const UserDetailsForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userId = location.state?.userId;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  const buttonRef = useRef(null);

  useEffect(() => {
    const button = buttonRef.current;
    let div = document.createElement('div'),
        letters = button.textContent.trim().split('');

    function elements(letter, index, array) {
      let element = document.createElement('span'),
          part = (index >= array.length / 2) ? -1 : 1,
          position = (index >= array.length / 2) ? array.length / 2 - index + (array.length / 2 - 1) : index,
          move = position / (array.length / 2),
          rotate = 1 - move;

      element.innerHTML = !letter.trim() ? '&nbsp;' : letter;
      element.style.setProperty('--move', move);
      element.style.setProperty('--rotate', rotate);
      element.style.setProperty('--part', part);

      div.appendChild(element);
    }

    letters.forEach(elements);

    button.innerHTML = div.outerHTML;

    button.addEventListener('mouseenter', () => {
      if (!button.classList.contains('out')) {
        button.classList.add('in');
      }
    });

    button.addEventListener('mouseleave', () => {
      if (button.classList.contains('in')) {
        button.classList.add('out');
        setTimeout(() => button.classList.remove('in', 'out'), 950);
      }
    });

  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isChecked) {
      alert('Debe aceptar los términos y condiciones antes de enviar los datos.');
      return;
    }
    try {
      const formData = {
        userId: userId,
        firstName: firstName,
        lastName: lastName,
        cellphone: cellphone
      };

      const response = await axios.post('http://oliviawilsoncitas.online/api/users/update-details', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      alert(response.data.message);

      // Navegar a SeleccionarServicios después de un envío exitoso
      navigate('/seleccionar-servicios', { state: { userId } });

    } catch (error) {
      console.error(error);
      alert('Failed to update user details');
    }
  };

  return (
    <div className="user-details-container-one">
      <div className="header">
        <img src={logo} alt="Logo" className="logo" /> 
        <h1>Agendamiento de Citas Olivia Wilson</h1>
      </div>
      <div className="user-details-container">
        <form className="user-details-form" onSubmit={handleSubmit}>
          <h2 className="user-details-title">Completa tus detalles de perfil</h2>
          <input
            type="text"
            placeholder="Nombre"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
            className="user-details-input"
          />
          <input
            type="text"
            placeholder="Apellidos"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
            className="user-details-input"
          />
          <input
            type="text"
            placeholder="Numero de Celular"
            value={cellphone}
            onChange={(e) => setCellphone(e.target.value)}
            required
            className="user-details-input"
          />
          <p className="user-details-info">
          Los datos proporcionados serán utilizados exclusivamente para la gestión y organización de sus citas en nuestro salón de belleza. Al continuar, usted acepta nuestros Términos y Condiciones y nuestra Política de Privacidad.
            <a href="http://oliviawilsoncitas.online/Tratamiento-de-Datos.pdf" target="_blank" rel="noopener noreferrer" className="user-details-read-more"> Leer más</a>
          </p>

          <label className="user-details-checkbox-container">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
              required
            />
            <p className='colorcito'>Acepto los términos y condiciones</p>
          </label>
          <button type="submit" className="button alternative" ref={buttonRef}>
            Enviar
          </button>
        </form>
      </div>
    </div>
  );
};

export default UserDetailsForm;






















