import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import "../styles/Auth.css";
import logo from "../images/logo.png";
import whatsapp from "../images/whatsapp.png";
import facebook from "../images/facebook.png"
import instagram from "../images/instagram.png"

axios.defaults.baseURL = 'http://oliviawilsoncitas.online';

function Auth() {
  const [isLogin] = useState(true);  // Declaración sin `setIsLogin`
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLogin) {
      try {
        const response = await axios.post('/api/users/login', { username, password });
        const userData = response.data.user;
        localStorage.setItem('user', JSON.stringify(userData));

        if (userData.rol === 'admin') {
          navigate('/admin-panel'); // Redirige al panel de administrador
        } else {
          navigate('/seleccionar-servicios', { state: { userId: userData.id } }); // Redirige a seleccionar-servicios con userId
        }
      } catch (error) {
        console.error('Error al iniciar sesión:', error);
        if (error.response && error.response.status === 404) {
          alert('Usuario no encontrado');
        } else if (error.response && error.response.status === 401) {
          alert('Contraseña incorrecta');
        } else {
          alert('Error al iniciar sesión');
        }
      }
    } else {
      try {
        const response = await axios.post('/api/users/register', { username, password, email });
        localStorage.setItem('user', JSON.stringify(response.data));
        navigate('/confirmacion');
      } catch (error) {
        console.error('Error al registrarse:', error);
        alert('Error al registrarse');
      }
    }
  };

  return (
    <div className='contenedor-principal-auth'>
      <div className="header">
        <img src={logo} alt="Logo" className="logo" />
        <h1>Agendamiento de Citas Olivia Wilson</h1>
      </div>
      <div className="welcome-banner">
        <h2>Acceso necesario para agendar citas</h2>
        <p>Para escoger un servicio y agendar tu cita, por favor inicia sesión o regístrate.</p>
      </div>

      <div className="auth-container">
        <div className="auth-form">
          <h2>{isLogin ? 'Iniciar Sesión' : 'Registro'}</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="username">Nombre de Usuario</label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            {!isLogin && (
              <div className="form-group">
                <label htmlFor="email">Correo Electrónico</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            )}
            <div className="form-group">
              <label htmlFor="password">Contraseña</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="submit-button">
              {isLogin ? 'Iniciar Sesión' : 'Registrarse'}
            </button>
          </form>
          <div className="toggle-auth">
            <p>
              {isLogin ? '¿No tienes una cuenta?' : '¿Ya tienes una cuenta?'}
              <span onClick={() => navigate('/register')}>
                {isLogin ? ' Regístrate aquí' : ' Inicia sesión aquí'}
              </span>
            </p>
            {isLogin && (
              <p>
                <span 
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => navigate('/recuperacion-pass')}
                >
                  ¿Olvidaste tu contraseña?
                </span>
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="footer">
  <p>Estamos aquí para hacerte sentir más hermosa y cuidada.</p>
  <div className="footer-menu">
    <div className="footer-option">
      <a href="https://www.instagram.com/palo_rosaarauca?igsh=MXNmMjBmd2tnajhsMw==" target="_blank" rel="noopener noreferrer">
        <img src={instagram} alt="Instagram" />
        <p>Instagram</p>
      </a>
    </div>
    <div className="footer-option">
      <a href="https://www.facebook.com/profile.php?id=100084094834817&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
        <img src={facebook} alt="Facebook" />
        <p>Facebook</p>
      </a>
    </div>
    <div className="footer-option">
      <a href="https://wa.me/+573219835941" target="_blank" rel="noopener noreferrer">
        <img src={whatsapp} alt="WhatsApp" />
        <p>WhatsApp</p>
      </a>
    </div>
  </div>
</div>
      <div className="credits">
        Esta página fue creada por <a href="mailto:medellincodestudios@gmail.com">Medellin Code Studios</a>
      </div>
    </div>
  );
}

export default Auth;
























