import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Register from './components/Register';
import Verify from './components/Verify';
import UserDetailsForm from './components/UserDetailsForm';
import HomePage from './components/HomePage';
import Auth from './components/Auth';
import SeleccionarServicios from './components/SeleccionarServicios';
import Confirmacion from './components/Confirmacion';
import CalendarioCitas from './components/CalendarioCitas';
import AdminPanel from './components/AdminPanel';
import RecuperacionPass from './components/RecuperacionPass';
import VisualizarCitas from './components/visualizarCitas';
import LoginVisualizar from './components/loginVisualizar';

const App = () => {
  const [userId, setUserId] = useState(null);

  const handleRegister = (id) => {
    setUserId(id);
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/visualizar-citas" element={<VisualizarCitas />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/login-visualizar" element={<LoginVisualizar />} />
        <Route path="/register" element={<Register onRegister={handleRegister} />} />
        <Route path="/verify" element={<Verify userId={userId} />} />
        <Route path="/user-details" element={<UserDetailsForm />} />
        <Route path="/seleccionar-servicios" element={<SeleccionarServicios />} />
        <Route path="/calendario" element={<CalendarioCitas />} />
        <Route path="/confirmacion" element={<Confirmacion />} />
        <Route path="/admin-panel" element={<AdminPanel />} /> {/* Agrega esta ruta */}
        <Route path="/recuperacion-pass" element={<RecuperacionPass />} />
      </Routes>
    </Router>
  );
};

export default App;












